<script setup lang="ts">
import { useNuxtApp } from '#app'

/**
 * props
 * ==================================================================
 */
interface Props {
  activeTrialDays: number
  isActiveTrial: boolean
  isExpiredTrial: boolean
}
defineProps<Props>()

/**
 * nuxt app
 * ==================================================================
 */
const { $growthbook } = useNuxtApp()

const trialExpirationEnabled = $growthbook?.isOn('trial-expiration') || false
</script>

<template>
  <v-chip
    v-if="trialExpirationEnabled"
    class="w-100 border-transparent"
    color="primary"
    border
    label
  >
    <span class="d-flex gap-8 align-center">
      <r-app-icon
        name="custom:InfoFilled"
        size="16"
        data-test="trial-chip-icon"
      />
      <span
        v-if="isActiveTrial"
        class="text-textblack font-12"
        data-test="active-trial-title"
      >
        Trial expires in
        <span class="font-weight-bold"> {{ activeTrialDays }} days.</span>
      </span>
      <span
        v-else-if="isExpiredTrial"
        class="text-textblack font-12"
        data-test="expired-trial-title"
      >
        Trial has expired!
      </span>
    </span>
  </v-chip>
</template>
